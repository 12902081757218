import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { basePathVrbt, baseVrbt } from "./app/constants";
import { AuthProvider } from "app/AuthContext";
import {
  getEncryptedCustParam,
  getEncryptedPhoneNumber,
  getOS,
} from "utils/helper";
import ScrollToTop from "utils/scrollToTop";

const RequirePhoneNumber = ({ children }) => {
  const phoneNumber = getEncryptedPhoneNumber();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const keyOs = queryParams.get("keyOs");
  const keyMsisdn = queryParams.get("keyMsisdn");

  if (keyOs !== "ios" && keyOs !== "android" && !phoneNumber) {
    return (
      <Navigate
        to={baseVrbt + `onboarding?keyMsisdn=${keyMsisdn}&keyOs=`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

const Middleware = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const keyMsisdn = queryParams.get("keyMsisdn");
  const custParam = queryParams.get("custParam");
  const keyOs = queryParams.get("keyOs");
  const isOnboarding = location.pathname.includes("/onboarding");

  const encryptData = getEncryptedCustParam();
  const os = getOS();

  useEffect(() => {
    const handleRedirection = async () => {
      if (isOnboarding) {
        if (encryptData === custParam && os === keyOs) {
          navigate(baseVrbt, { replace: true, state: { from: location } });
        } else if (keyOs !== "android" && keyOs !== "ios") {
          navigate(
            `${baseVrbt}onboarding?keyOs=${keyOs}&keyMsisdn=${keyMsisdn}`,
            { replace: true, state: { from: location } }
          );
        } else {
          navigate(
            `${baseVrbt}onboarding?keyOs=${keyOs}&custParam=${custParam}`,
            { replace: true, state: { from: location } }
          );
        }
      }
    };

    handleRedirection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

const MiddlewareRedirect = ({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const validParams = ["category", "artist", "song"];
    const hasValidQuery = Array.from(searchParams.keys()).some((key) =>
      validParams.includes(key)
    );

    if (
      hasValidQuery &&
      location.pathname === baseVrbt &&
      getOS() !== "android" &&
      getOS() !== "ios"
    ) {
      console.log("this fired?");
      const redirectUrl = baseVrbt + `search?${searchParams.toString()}`;
      navigate(redirectUrl, { replace: true, state: { journey: true } });
    }
  }, [searchParams, navigate, location]);

  return children;
};

const WapPage = React.lazy(() => import(basePathVrbt + "WapPage/"));
const WapOnboardingPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/OnboardingPage")
);
const WapDetailPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/DetailPage")
);
const WapSearchPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/SearchPage")
);
const WapGroupPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/GroupPage")
);
const WapCollectionPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/CollectionPage")
);
const WapCollectionDetailPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/CollectionDetailPage")
);
const WapTnCPage = React.lazy(() => import(basePathVrbt + "WapPage/TnCPage"));
const WapLoginPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/LoginPage")
);
const WapPermissionPage = React.lazy(() =>
  import(basePathVrbt + "WapPage/PermissionPage")
);
///// VRBT CMS

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<></>}>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <MiddlewareRedirect>
            <Middleware>
              <Routes>
                {/* WAP */}
                <Route
                  path={baseVrbt}
                  element={
                    <RequirePhoneNumber>
                      <WapPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route path={baseVrbt + "login"} element={<WapLoginPage />} />
                <Route
                  path={baseVrbt + "onboarding"}
                  element={<WapOnboardingPage />}
                />
                <Route
                  path={baseVrbt + "permission"}
                  element={<WapPermissionPage />}
                />
                <Route
                  path={baseVrbt + "content/:content_id"}
                  element={
                    <RequirePhoneNumber>
                      <WapDetailPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route
                  path={baseVrbt + "search"}
                  element={
                    <RequirePhoneNumber>
                      <WapSearchPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route
                  path={baseVrbt + "group/:content_class_id"}
                  element={
                    <RequirePhoneNumber>
                      <WapGroupPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route
                  path={baseVrbt + "collection"}
                  element={
                    <RequirePhoneNumber>
                      <WapCollectionPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route
                  path={baseVrbt + "collection-details/"}
                  element={
                    <RequirePhoneNumber>
                      <WapCollectionDetailPage />
                    </RequirePhoneNumber>
                  }
                />
                <Route path={baseVrbt + "tnc"} element={<WapTnCPage />} />
                <Route
                  path="*"
                  element={
                    <RequirePhoneNumber>
                      <WapPage />
                    </RequirePhoneNumber>
                  }
                />
              </Routes>
            </Middleware>
          </MiddlewareRedirect>
        </Router>
      </AuthProvider>
    </React.Suspense>
  );
};
export default ProjectRoutes;
